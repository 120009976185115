<template>
  <div>
    <v-divider></v-divider>
    <v-layout wrap fill-height>
      <v-flex xs12 sm12 md3 lg2 class="hidden-sm-and-down">
        <v-navigation-drawer
          class="fixedSidebar"
          clipped
          width="100%"
          height="100vh"
          permanent
          style="background-color: #fafafa"
        >
          <v-layout style="background-color: #fff; height: 85px !important">
            <v-flex xs12 pl-4 align-self-center>
              <v-img
                contain
                width="95%"
                height="150px"
                src="../assets/sarpaodisha_1.png"
                fill-height
              ></v-img>
            </v-flex>
          </v-layout>
          <v-list dense nav>
            <v-list-item
              v-for="item in sarpadashboard"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md9 lg10>
        <v-layout wrap pb-0 mb-0>
          <v-flex xs12 sm12 md12 lg12 pb-0 mb-0>
            <SarpaAppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex xs12 pt-0 mt-0
            ><v-divider class="pt-0 mt-0"></v-divider
          ></v-flex>
          <v-flex xs12 sm12 md12 lg12 pl-3>
            <v-layout
              wrap
              justify-center
              :style="
                $route.name == 'adminDashboard'
                  ? 'background-color: #ffffff'
                  : 'background-color: #f6fff9'
              "
            >
              <v-flex xs12>
                <v-card
                  min-height="100vh"
                  :color="
                    $route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'
                  "
                  flat
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <router-view :key="$route.fullPath"></router-view>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import SarpaAppHeader from "@/components/Common/appHeadersarpa";

export default {
  components: {
    SarpaAppHeader,
  },
  data() {
    return {
      sarpadashboard: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/Sarpa/dashboard",
        },
        // {
        //   title: "Statistics ",
        //   icon: "mdi-chart-bar",
        //   path: "/admin/commonanalyticspage",
        // },
        {
          title: "Add Snakes",
          icon: "mdi-snake",
          path: "/addSnakes",
        },
        {
          title: "All Users",
          icon: "mdi-account-group",
          path: "/usersList",
        },
        // {
        //   title: "Emergency Officers",
        //   icon: "mdi-account-star",
        //   path: "/alldisEmergencyOfficer",
        // },
        {
          title: "New Rescuers",
          icon: "mdi-account-plus",
          path: "/newrescuerslist",
        },
        {
          title: "Rescuer List",
          icon: "mdi-account-multiple-plus ",
          path: "/allrescuerlist",
        },
        {
          title: "DMO List",
          icon: "mdi-doctor",
          path: "/alldmolist",
        },
        {
          title: "Emergency Officers",
          icon: "mdi-account-hard-hat",
          path: "/alldistrictlist",
        },
        {
          title: "Pending Operations",
          icon: "mdi-playlist-remove",
          path: "/pendingoperations",
        },
        {
          title: "Assigned Operations",
          icon: "mdi-view-list",
          path: "/assignedoperations",
        },
        {
          title: "Rescued Operations",
          icon: "mdi-playlist-plus",
          path: "/rescuedoperations",
        },
        {
          title: "Released Operations",
          icon: "mdi-format-list-checks",
          path: "/releasedoperations",
        },
        {
          title: "Approved Operations",
          icon: "mdi-playlist-check",
          path: "/approvedoperations",
        },
        {
          title: "Delete Operations",
          icon: "mdi-delete-sweep",
          path: "/deleteoperations",
        },
        {
          title: "Training List",
          icon: "mdi-format-list-bulleted-triangle",
          path: "/traininglist",
        },
        {
          title: "Resource Images",
          icon: "mdi-image",
          path: "/imageList",
        },
        {
          title: "Resource Videos",
          icon: "mdi-video",
          path: "/videoList",
        },
        {
          title: "Snake Reports",
          icon: "mdi-file-chart",
          path: "/snakereports",
        },
        {
          title: "Add Snake Experts",
          icon: "mdi-plus",
          path: "/addsnakeexperts",
        },
        {
          title: "Add Hospital",
          icon: "mdi-hospital-building",
          path: "/addhospital",
        },
        {
          title: "View Hospital",
          icon: "mdi-hospital-marker",
          path: "/Viewhospital",
        },
        {
          title: "Venom Details",
          icon: "mdi-hospital",
          path: "/listVenom",
        },
        {
          title: "Identification Help",
          icon: "mdi-help-box-multiple-outline",
          path: "/identificationhelp",
        },
        // {
        //   title: "Approve Hospital",
        //   icon: "mdi-check-decagram-outline",
        //   path: "/approvehospital",
        // },
      ],
      right: null,
    };
  },
};
</script>
